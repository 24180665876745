'use strict';
var $ = window.$;

$(document).ready(function () {

  $.fancybox.defaults.touch = false;
  // $.fancybox.defaults.toolbar = false;
  $.fancybox.defaults.infobar = false;
  $.fancybox.defaults.closeExisting = true;
  // $.fancybox.defaults.arrows = false;
  // $.fancybox.defaults.autoFocus = false;

  // Слайдеры
  $('[customers-slider]').each(function () {
    let $block = $(this);
    let $init = $block.find('[class*="__list"]');
    let $next = $block.find('[class*="_next"]');
    let $prev = $block.find('[class*="_prev"]');
    let $dots = $block.find('.slider-dots');

    $init.slick({
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      nextArrow: $next,
      prevArrow: $prev,
      appendDots: $dots,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          }
        },
      ]
    })
  })

  $('[document-slider]').each(function () {
    let $block = $(this);
    let $init = $block.find('[class*="__list"]');
    let $next = $block.find('[class*="_next"]');
    let $prev = $block.find('[class*="_prev"]');
    let $dots = $block.find('.slider-dots');

    $init.slick({
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      nextArrow: $next,
      prevArrow: $prev,
      appendDots: $dots,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
      ]
    })
  })

  $('[gallery-slider]').each(function () {
    let $block = $(this);
    let $init = $block.find('[class*="__list"]');
    let $next = $block.find('[class*="_next"]');
    let $prev = $block.find('[class*="_prev"]');
    let $dots = $block.find('.slider-dots');

    $init.slick({
      arrows: true,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      nextArrow: $next,
      prevArrow: $prev,
      appendDots: $dots,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
      ]
    })
  })

  // Открытие мобильного меню
  $('.js-toggle-menu').on('click', function (e) {
    $('#mobile-menu').toggleClass('is-open');
    $('#hamburger').toggleClass('is-open');
  });

  $('.js-close-menu').on('click', function (e) {
    $('#mobile-menu').removeClass('is-open');
    $('#hamburger').removeClass('is-open');
  });

  // плавная прокрутка
  $('a[href*="#"]').on("click", function (event) {
    event.preventDefault();
    var id = $(this).attr('href');
    var top = $(id).offset().top;
    $('#mobile-menu').removeClass('is-open');
    $('body,html').animate({
      scrollTop: top
    }, 500);
  });

  // фиксированный хедер
  $(window).scroll(function (event) {
    const st = $(this).scrollTop();
    const headLine = $('.head-dashboard__bottom');
    const headHeight = headLine.height();
    const headSt = $('.head-dashboard__wrap').height()
    if (st >= headSt) {
      $('.head-dashboard__wrap').css('padding-bottom', headHeight)
      headLine.addClass('isFixed')
    } else {
      $('.head-dashboard__wrap').css('padding-bottom', 0)
      headLine.removeClass('isFixed')
    }
  });

  $('[send-mail]').on('submit', function(e){
    e.preventDefault();
    sendAjaxForm($(this));
  })

  function sendAjaxForm(form) {
    $.ajax({
      url: 'send-mail/send-mail.php', //url страницы (action_ajax_form.php)
      type: "POST", //метод отправки
      dataType: "html", //формат данных
      data: form.serialize(),  // Сеарилизуем объект
      success: function (response) { //Данные отправлены успешно
        $.fancybox.open($('#success-modal'));
      },
      error: function (response) { // Данные не отправлены
        $.fancybox.open($('#error-modal'));
      }
    });
  }
});